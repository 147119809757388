import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo";
import TypeToBlock from "../../components/type-to-block";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
    fitplans: contentfulPages(
      slug: { eq: "fitplans" }
      node_locale: { eq: $languageISO }
    ) {
      slug
      id
      metaDescription
      metaTitle
      modules {
        id
        type
        items {
          ... on ContentfulPlanModule {
            id
            gender
            metaTitle
            planName
            planSlug
            shortDescription
            planGoals
            featureImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            featureImageMobile {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
`;

const Plans = ({
  data: {
    fitplans: { metaTitle, metaDescription, modules },
    ribbon,
    header,
    footer,
  },
}) => {
  return (
    <>
      <Layout
        header={header}
        ribbon={ribbon}
        footer={footer}
        hasBanner="white-banner"
      >
        <Seo title={metaTitle} metaDescription={metaDescription} />
        {modules.map((module, key) => (
          <TypeToBlock key={`module-${key}`} module={module} />
        ))}
      </Layout>
    </>
  );
};

export default Plans;
